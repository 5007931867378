@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Chakra Petch', sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.App {
  width: 90%;
  max-width: 700px;
  margin: 2em auto;
}
p.lorem-text {
  text-align: justify;
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}
._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

.center-img {
  display: block;
  margin: auto;
  transform: translateX(-50%);
}
.react-datepicker__portal {
  z-index: 10000 !important;
}

.content {
  transition: margin-left 0.3s ease-in-out;
}

.content.full-width {
  margin-left: 0;
}

.app {
  display: flex;
}
html {
  background-color: var(--primary-color) !important;
}

.select:not(.is-multiple):not(.is-loading)::after,
.navbar-link:not(.is-arrowless)::after {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  /* transform: rotate(-45deg); */
  /* transform-origin: center; */
  width: 0.625em;
}

section {
  display: block;
}

.hero.is-halfheight .hero-body,
.hero.is-fullheight .hero-body,
.hero.is-fullheight-with-navbar .hero-body {
  align-items: center;
  display: flex;
}

.hero.is-halfheight .hero-body > .container,
.hero.is-fullheight .hero-body > .container,
.hero.is-fullheight-with-navbar .hero-body > .container {
  flex-grow: 1;
  flex-shrink: 1;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

.columns:last-child {
  margin-bottom: -0.75rem;
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.75rem);
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.hero.is-fullheight {
  min-height: 100vh;
}

.has-background-grey-light {
  background-color: #141b2d !important;
}

.select.is-fullwidth {
  width: 100%;
}

.hero {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
}

.container {
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;
  justify-content: center;
}
.columns.is-centered {
  justify-content: center;
}

.column.is-4-desktop {
  flex: none;
  width: 33.33333%;

  margin: 0 auto;
}

.box {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  display: block;
}

a.box:hover,
a.box:focus {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px #485fc7;
}

a.box:active {
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.2), 0 0 0 1px #485fc7;
}

.has-text-centered {
  text-align: center !important;
}

.mt-5 {
  margin-top: 1.5rem !important;
}

.label {
  color: #e0e0e0;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.button,
.input,
.textarea,
.select select,
.file-cta,
.file-name,
.pagination-previous,
.pagination-next,
.pagination-link,
.pagination-ellipsis {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
}

button,
input,
select,
textarea {
  margin: 0;
}

body,
button,
input,
optgroup,
select,
textarea {
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}

.input,
.textarea,
.select select {
  background-color: white;
  border-color: #434242;
  border-radius: 4px;
  color: #363636;
}

.input::-moz-placeholder,
.textarea::-moz-placeholder,
.select select::-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input::-webkit-input-placeholder,
.textarea::-webkit-input-placeholder,
.select select::-webkit-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input:-moz-placeholder,
.textarea:-moz-placeholder,
.select select:-moz-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder,
.select select:-ms-input-placeholder {
  color: rgba(54, 54, 54, 0.3);
}

.input:hover,
.textarea:hover,
.select select:hover,
.is-hovered.input,
.is-hovered.textarea,
.select select.is-hovered {
  border-color: #b5b5b5;
}

.input:focus,
.textarea:focus,
.select select:focus,
.is-focused.input,
.is-focused.textarea,
.select select.is-focused,
.input:active,
.textarea:active,
.select select:active,
.is-active.input,
.is-active.textarea,
.select select.is-active {
  border-color: #485fc7;
  box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25);
}

.input[disabled]::-moz-placeholder,
.textarea[disabled]::-moz-placeholder,
.select select[disabled]::-moz-placeholder,
fieldset[disabled] .input::-moz-placeholder,
fieldset[disabled] .textarea::-moz-placeholder,
fieldset[disabled] .select select::-moz-placeholder,
.select fieldset[disabled] select::-moz-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

.input[disabled]::-webkit-input-placeholder,
.textarea[disabled]::-webkit-input-placeholder,
.select select[disabled]::-webkit-input-placeholder,
fieldset[disabled] .input::-webkit-input-placeholder,
fieldset[disabled] .textarea::-webkit-input-placeholder,
fieldset[disabled] .select select::-webkit-input-placeholder,
.select fieldset[disabled] select::-webkit-input-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

.input[disabled]:-moz-placeholder,
.textarea[disabled]:-moz-placeholder,
.select select[disabled]:-moz-placeholder,
fieldset[disabled] .input:-moz-placeholder,
fieldset[disabled] .textarea:-moz-placeholder,
fieldset[disabled] .select select:-moz-placeholder,
.select fieldset[disabled] select:-moz-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

.input[disabled]:-ms-input-placeholder,
.textarea[disabled]:-ms-input-placeholder,
.select select[disabled]:-ms-input-placeholder,
fieldset[disabled] .input:-ms-input-placeholder,
fieldset[disabled] .textarea:-ms-input-placeholder,
fieldset[disabled] .select select:-ms-input-placeholder,
.select fieldset[disabled] select:-ms-input-placeholder {
  color: rgba(122, 122, 122, 0.3);
}

.input,
.textarea {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 80%;
  width: 80%;
}

.input[readonly],
.textarea[readonly] {
  box-shadow: none;
}

.is-white.input,
.is-white.textarea {
  border-color: white;
}

.is-white.input:focus,
.is-white.textarea:focus,
.is-white.is-focused.input,
.is-white.is-focused.textarea,
.is-white.input:active,
.is-white.textarea:active,
.is-white.is-active.input,
.is-white.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25);
}

.is-black.input,
.is-black.textarea {
  border-color: #0a0a0a;
}

.is-black.input:focus,
.is-black.textarea:focus,
.is-black.is-focused.input,
.is-black.is-focused.textarea,
.is-black.input:active,
.is-black.textarea:active,
.is-black.is-active.input,
.is-black.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25);
}

.is-light.input,
.is-light.textarea {
  border-color: whitesmoke;
}

.is-light.input:focus,
.is-light.textarea:focus,
.is-light.is-focused.input,
.is-light.is-focused.textarea,
.is-light.input:active,
.is-light.textarea:active,
.is-light.is-active.input,
.is-light.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25);
}

.is-dark.input,
.is-dark.textarea {
  border-color: #363636;
}

.is-dark.input:focus,
.is-dark.textarea:focus,
.is-dark.is-focused.input,
.is-dark.is-focused.textarea,
.is-dark.input:active,
.is-dark.textarea:active,
.is-dark.is-active.input,
.is-dark.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25);
}

.is-primary.input,
.is-primary.textarea {
  border-color: #00d1b2;
}

.is-primary.input:focus,
.is-primary.textarea:focus,
.is-primary.is-focused.input,
.is-primary.is-focused.textarea,
.is-primary.input:active,
.is-primary.textarea:active,
.is-primary.is-active.input,
.is-primary.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(0, 209, 178, 0.25);
}

.is-link.input,
.is-link.textarea {
  border-color: #485fc7;
}

.is-link.input:focus,
.is-link.textarea:focus,
.is-link.is-focused.input,
.is-link.is-focused.textarea,
.is-link.input:active,
.is-link.textarea:active,
.is-link.is-active.input,
.is-link.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25);
}

.is-info.input,
.is-info.textarea {
  border-color: #3e8ed0;
}

.is-info.input:focus,
.is-info.textarea:focus,
.is-info.is-focused.input,
.is-info.is-focused.textarea,
.is-info.input:active,
.is-info.textarea:active,
.is-info.is-active.input,
.is-info.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25);
}

.is-success.input,
.is-success.textarea {
  border-color: #48c78e;
}

.is-success.input:focus,
.is-success.textarea:focus,
.is-success.is-focused.input,
.is-success.is-focused.textarea,
.is-success.input:active,
.is-success.textarea:active,
.is-success.is-active.input,
.is-success.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(72, 199, 142, 0.25);
}

.is-warning.input,
.is-warning.textarea {
  border-color: #ffe08a;
}

.is-warning.input:focus,
.is-warning.textarea:focus,
.is-warning.is-focused.input,
.is-warning.is-focused.textarea,
.is-warning.input:active,
.is-warning.textarea:active,
.is-warning.is-active.input,
.is-warning.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25);
}

.is-danger.input,
.is-danger.textarea {
  border-color: #f14668;
}

.is-danger.input:focus,
.is-danger.textarea:focus,
.is-danger.is-focused.input,
.is-danger.is-focused.textarea,
.is-danger.input:active,
.is-danger.textarea:active,
.is-danger.is-active.input,
.is-danger.is-active.textarea {
  box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0.25);
}

.is-small.input,
.is-small.textarea {
  border-radius: 2px;
  font-size: 0.75rem;
}

.is-medium.input,
.is-medium.textarea {
  font-size: 1.25rem;
}

.is-large.input,
.is-large.textarea {
  font-size: 1.5rem;
}

.is-fullwidth.input,
.is-fullwidth.textarea {
  display: block;
  width: 100%;
}

.is-inline.input,
.is-inline.textarea {
  display: inline;
  width: auto;
}

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(calc(0.75em - 1px) + 0.375em);
  padding-right: calc(calc(0.75em - 1px) + 0.375em);
}

.input.is-static {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
}

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(0.75em - 1px);
  resize: vertical;
}

.textarea:not([rows]) {
  max-height: 40em;
  min-height: 8em;
}

.textarea[rows] {
  height: initial;
}

.textarea.has-fixed-size {
  resize: none;
}

/* CSS */
.button-46 {
  align-items: right;
  background-color: rgba(240, 240, 240, 1);
  border: 1px solid #ffffff;
  border-radius: 16px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: flex;
  float: right;
  font-family: Inter, sans-serif;
  font-size: 8px;
  justify-content: center;
  line-height: 18px;
  max-width: 10%;
  padding: 4px 8px;
  text-decoration: none;
  transition: all 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 10%;
}

.button-46:active,
.button-46:hover {
  outline: 0;
}

.button-46:hover {
  background-color: #ffffff;
  border-color: rgba(254, 254, 254, 0.19);
}

@media (min-width: 768px) {
  .button-46 {
    font-size: 14px;
    min-width: 100px;
    padding: 6px 8px;
  }
}
p.warning {
  color: #ee1616;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}
p.valid {
  color: greenyellow;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}
h3.settings {
  color: #e0e0e0;
  display: block;
  font-size: 22px;
  font-weight: 700;
  margin-top: 0px;
}

h4.settings {
  color: #e0e0e0;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-right: 50px;
}

.pen_box {
  /* background-color: #1F2A40; */
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  display: block;
  padding: 2.25rem;
  width: 90%;
  margin: auto;
}

td.settings {
  border-top: 2px solid #141b2d;
}
th.settings {
  border-bottom: 0px solid #141b2d;
}
.custom-calendar {
  background-color: #f7f7f7;
}

.redbox {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.greenbox {
  margin-left: 20px;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.text {
  display: inline-block;
  margin-left: 5px;
}
.icon-scale {
  transition: transform 0.3s;
}

.icon-scale:hover {
  transform: scale(1.2); /* Adjust the scale factor as desired */
}

table tr:nth-child(odd) {
  background-color: rgba(0, 0, 100, 0.1); /* Your desired color for odd rows */
}

table th{
  background-color: rgba(0, 0, 150, 0.05);
}
.active-submenu {
  color: green !important;
}

.inactive-submenu {
  color: grey !important;
}

.myDatePicker {
  width: 800px; /* Replace with your desired width */
}

.MuiInputLabel-root {
  width: 500px !important;
}


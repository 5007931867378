/* customDatePicker.css */

.react-datepicker__input-container {
    display: inline-block;
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    display: inline-block;
    width: 100%;
    background-color: transparent;
    border: 1px solid;
    color: #ffffff;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
  }
  
  .react-datepicker__input-container input:focus {
    outline: none;
    border-color: #ffffff;
  }
  .react-datepicker__portal {
    z-index: 10000 !important;
}
.MuiDialogContent-root {
  overflow: visible !important;
}
.customDialogContent {
  overflow: visible !important;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth {
  width: 90%;
  max-width: 400px;
  border: 1px solid #dcdcdc;
  padding: 20px;
  background-color: white;
  text-align: center;
}

.auth form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto 10px auto;
}

.auth__success {
  padding: 10px 0;
  background-color: hsl(147, 79%, 87%);
  margin-bottom: 20px;
}

.auth__error {
  padding: 10px 0;
  background-color: hsl(0, 79%, 87%);
  margin-bottom: 20px;
}

.auth form > input,
button {
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
}

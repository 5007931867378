.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .blur {
    filter: blur(5px);
  }
  /* SwitchModal.css */

  .custom-dialog {
    top: 10px !important; /* Adjust the value to your preference */
    margin: 0;
    transform: none !important;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .tabs-container {
    display: flex;
  }
  
  .tab {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tab:hover {
    background-color: #f0f0f0;
  }
  
  .tab-active {
    font-weight: bold;
    background-color: #d0d0d0; /* Active tab background color */
  }
  
